<div class="wrapper-container">
  <main>
    <section class="video-banner-wrap position-relative">
      <img src="assets/images/group-dots.svg" class="position-absolute img-1" width="65" height="95" alt="group-dots" />
      <img src="assets/images/banner-circle-cut.svg" class="position-absolute img-2" width="148" height="148" alt="banner-circle-cut" />
      <img src="assets/images/banner-half-circle.svg" class="position-absolute img-3" width="107" height="107" alt="banner-half-circle" />
      <img src="assets/images/black-circle.svg" class="position-absolute img-4" width="50" height="50" alt="black-circle" />
      <div class="video-banner-inner">
        <div class="video-banner-top">
          <img src="assets/images/logo.png" class="mx-auto" width="148" height="148" alt="Logo" />
          <h1>The Search Stylist</h1>
          <h3>Empowering <span>Individual</span> Style for All</h3>
          <p>The Search Stylist is the ultimate precision search engine for apparel. Our platform will incorporate a wide range of brands, and online apparel stores within one convenient location.</p>
        </div>
        <div class="video-section position-relative">
          <img src="assets/images/video-banner1.png" class="img-fluid" alt="video-banner1">
          <button type="button" class="videobanner-playbtn bg-transparent border-0 p-0" data-bs-toggle="modal" data-bs-target="#videoModal">
            <img src="assets/images/video-banner-play.svg" class="img-fluid" alt="video-banner-play">
          </button>
        </div>
      </div>
    </section>
    <!-- Video Modal -->
    <div class="modal fade border-0 vide-modal" id="videoModal" tabindex="-1">
      <div class="modal-dialog overflow-hidden">
          <div class="modal-content h-100 border-0 p-3 p-md-4">
              <div class="modal-header px-0 border-0 pt-0">
                  <button type="button" class="btnclose d-flex justify-content-center align-items-center bg-transparent border-0 p-0 position-static" data-bs-dismiss="modal" aria-label="Close">
                      <img src="assets/images/cross.svg" alt="cross">
                  </button>
              </div>
              <div class="modal-body p-0">
                  <div class="video-container">
                      <iframe id="videoIframe1" allowfullscreen="" src="https://www.youtube.com/embed/_sIjc7cDCQE"></iframe>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <section class="partner-with-us">
      <div class="content">
        <h2>What is The Search Stylist</h2>
        <p>The Search Stylist is the ultimate precision search engine for apparel. Our platforms vision is to bring together a diverse range of brands and online apparel stores in one convenient location with AI backed search to give customers the best shopping experience. </p>
        <p>Whether someone’s seeking high-end fashion, affordable brands, second-hand apparel, or any other type of clothing, our comprehensive database and search precision AI will ensure that they find exactly what they're looking for. </p>
        <p>The days of endless browsing are gone. Individuals will now have intelligent style catalogs created with their exact preferences considered.</p>
      </div>
      <div class="image">
        <img src="assets/images/home2.png" class="aiImg1" alt="Image" />
        <img src="assets/images/mhome2.png" class="aiImg2" alt="Image" />
      </div>
    </section>
    <section class="image-slider-wrap">
        <div class="image-slider-top">
            <h2>Empowering your style by empowering how you shop</h2>
        </div>
        <div class="about-image-slider">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
            <div ngxSlickItem class="slide position-relative">
              <!-- <img src="assets/images/slide-1.png" alt="slide-2" width="100%"> -->
                <img src="assets/images/action_1.png" alt="slide-1" width="100%">
                <button type="button" class="videobanner-playbtn bg-transparent border-0 p-0" data-bs-toggle="modal" data-bs-target="#videoSliderModal1">
                  <img src="assets/images/slider-play.svg" class="img-fluid" alt="slider-play">
                </button>
            </div>
            <div ngxSlickItem class="slide position-relative">
              <!-- <img src="assets/images/slide-2.png" alt="slide-2" width="100%"> -->
              <img src="assets/images/action_2.png" alt="slide-2" width="100%">
              <button type="button" class="videobanner-playbtn bg-transparent border-0 p-0" data-bs-toggle="modal" data-bs-target="#videoSliderModal2">
                <img src="assets/images/slider-play.svg" class="img-fluid" alt="slider-play">
              </button>
            </div>
            <div ngxSlickItem class="slide position-relative">
              <!-- <img src="assets/images/slide-3.png" alt="slide-3" width="100%"> -->
              <img src="assets/images/action_3.png" alt="slide-3" width="100%">
              <button type="button" class="videobanner-playbtn bg-transparent border-0 p-0" data-bs-toggle="modal" data-bs-target="#videoSliderModal3">
                <img src="assets/images/slider-play.svg" class="img-fluid" alt="slider-play">
              </button>
            </div>
        </ngx-slick-carousel>
        </div>
    </section>
    <!-- Video Slider Modal 1-->
    <div class="modal fade border-0 vide-modal" id="videoSliderModal1" tabindex="-1">
      <div class="modal-dialog overflow-hidden">
          <div class="modal-content h-100 border-0 p-3 p-md-4">
              <div class="modal-header px-0 border-0 pt-0">
                  <button type="button" class="btnclose d-flex justify-content-center align-items-center bg-transparent border-0 p-0 position-static" data-bs-dismiss="modal" aria-label="Close">
                      <img src="assets/images/cross.svg" alt="cross">
                  </button>
              </div>
              <div class="modal-body p-0">
                  <div class="video-container">
                      <iframe id="videoIframe1" allowfullscreen="" src="https://www.youtube.com/embed/T_yh7X5NyI4?si=z9TJb-ojElEgj0w5"></iframe>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- Video Slider Modal 2-->
    <div class="modal fade border-0 vide-modal" id="videoSliderModal2" tabindex="-1">
      <div class="modal-dialog overflow-hidden">
          <div class="modal-content h-100 border-0 p-3 p-md-4">
              <div class="modal-header px-0 border-0 pt-0">
                  <button type="button" class="btnclose d-flex justify-content-center align-items-center bg-transparent border-0 p-0 position-static" data-bs-dismiss="modal" aria-label="Close">
                      <img src="assets/images/cross.svg" alt="cross">
                  </button>
              </div>
              <div class="modal-body p-0">
                  <div class="video-container">
                      <iframe id="videoIframe1" allowfullscreen="" src="https://www.youtube.com/embed/GbNq1NsJX18?si=PVJ-g_ruCYLBYgLX"></iframe>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <!-- Video Slider Modal 2-->
    <div class="modal fade border-0 vide-modal" id="videoSliderModal3" tabindex="-1">
      <div class="modal-dialog overflow-hidden">
          <div class="modal-content h-100 border-0 p-3 p-md-4">
              <div class="modal-header px-0 border-0 pt-0">
                  <button type="button" class="btnclose d-flex justify-content-center align-items-center bg-transparent border-0 p-0 position-static" data-bs-dismiss="modal" aria-label="Close">
                      <img src="assets/images/cross.svg" alt="cross">
                  </button>
              </div>
              <div class="modal-body p-0">
                  <div class="video-container">
                      <iframe id="videoIframe1" allowfullscreen="" src="https://www.youtube.com/embed/5msXTD0DVY4?si=UqdyyrUe8x488-Tv"></iframe>
                  </div>
              </div>
          </div>
      </div>
    </div>
    <section class="form-tabs-wrap">
        <div class="form-tabs">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="customer-form-tab" data-bs-toggle="tab" data-bs-target="#customer-form" type="button" role="tab" aria-controls="customer-form" aria-selected="true">For Customer</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="brands-form-tab" data-bs-toggle="tab" data-bs-target="#brands-form" type="button" role="tab" aria-controls="brands-form" aria-selected="false">For Brands <span class="mob-none">and Stores</span></button>
            </li>
          </ul>

          <!-- Tab panes -->
          <div class="tab-content">
            <div class="tab-pane active" id="customer-form" role="tabpanel" aria-labelledby="customer-form-tab">
              <div class="form-wrap">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                      <label for="first-name">Name</label>
                      <input formControlName="name" type="text" id="first-name" name="first-name" placeholder="Enter first name" required>
                    </div>
                    <div class="form-group">
                      <label for="first-name">Email</label>
                      <input formControlName="email" type="text" id="email" name="email" placeholder="Enter email address" required>
                    </div>
                    <!-- <div class="form-group">
                      <label for="brand-name">Brand Name</label>
                      <input formControlName="Brand Name" type="text" id="brand-name" name="brand-name" placeholder="Enter Brand Name" required>
                    </div> -->
                    <div class="form-group">
                      <label for="first-name">Phone Number</label>
                      <input formControlName="phoneNumber" type="text" id="phone" name="phone" placeholder="Enter phone number" required>
                    </div>
                    <div class="form-group">
                      <label for="message">Message</label>
                      <textarea formControlName="message" name="message" id="message" rows="3"  placeholder="Message"></textarea>
                    </div>
                    <div class="form-group">
                      <input class="btn" type="submit" value="Submit">
                    </div>
                    <ng-container *ngIf="formSubmitted">
                    <div class="form-group">
                      <label>Form successfully submitted</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showError">
                    <div class="form-group">
                      <label style="color: red;">Please fill all the fields with valid data</label>
                    </div>
                  </ng-container>
                </form>
              </div>
            </div>
            <div class="tab-pane" id="brands-form" role="tabpanel" aria-labelledby="brands-form-tab">
              <div class="form-wrap">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                      <label for="first-name">Brand Name</label>
                      <input formControlName="name" type="text" id="first-name" name="first-name" placeholder="Enter brand name" required>
                    </div>
                    <div class="form-group">
                      <label for="first-name">Email</label>
                      <input formControlName="email" type="text" id="email" name="email" placeholder="Enter email address" required>
                    </div>
                    <div class="form-group">
                      <label for="first-name">Phone Number</label>
                      <input formControlName="phoneNumber" type="text" id="phone" name="phone" placeholder="Enter phone number" required>
                    </div>
                    <div class="form-group">
                      <label for="message">Message</label>
                      <textarea formControlName="message" name="message" id="message" rows="3"  placeholder="Message"></textarea>
                    </div>
                    <div class="form-group">
                      <input class="btn" type="submit" value="Submit">
                    </div>
                  <ng-container *ngIf="formSubmitted">
                    <div class="form-group">
                      <label>Form successfully submitted</label>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="showError">
                    <div class="form-group">
                      <label style="color: red;">Please fill all the fields with valid data</label>
                    </div>
                  </ng-container>
                </form>
              </div>
            </div>
          </div>
        </div>
    </section>
  </main>
  <footer>
    <div class="footer-wrap">
      <p>The Search Stylist 2023. All rights reserved.</p>
      <div class="socail-links">
          <a href="https://www.instagram.com/the_search_stylist/" target="_blank"><img src="assets/images/instagram.svg" alt="instagram" /></a>
          <a href="https://www.youtube.com/channel/UCCx-_ZwylAhaGwqNcQGZ3IA" target="_blank"><img src="assets/images/youtube.svg" alt="youtube" /></a>
          <a href="https://www.tiktok.com/@thesearchstylist" target="_blank"><img src="assets/images/tiktok.svg" alt="tiktok" /></a>
          <!-- <a href=""><img src="assets/images/twitter.svg" alt="twitter" /></a> -->
          <a href="https://www.facebook.com/profile.php?id=61553231694839&is_tour_completed=true" target="_blank"><img src="assets/images/facebook.svg" alt="facebook" /></a>
          <!-- <a href=""><img src="assets/images/github.svg" alt="github" /></a> -->
      </div>
    </div>
  </footer>
</div>
