import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ApiService } from '../shared/services/api.service'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  contactForm!: FormGroup;
  isLoading: boolean = false;
  formSubmitted: boolean = false;
  showError: boolean = false;

  slides = [
    {img: "http://placehold.it/350x150/000000"},
    {img: "http://placehold.it/350x150/111111"},
    {img: "http://placehold.it/350x150/333333"},
    {img: "http://placehold.it/350x150/666666"}
  ];
  slideConfig = {"slidesToShow": 1, "slidesToScroll": 1, "arrows": true, "dots": true , "autoplay":true,};

  constructor(  private formBuilder: FormBuilder,private apiService:ApiService) { }

  ngOnInit(): void {
    this.createContactForm();
  }

  createContactForm(): void {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      websiteLink: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  onSubmit(){
    this.contactForm.get('websiteLink')?.setValue('https://abc.com')
    if(!this.contactForm.valid){
      this.showError = true;
      setTimeout(() => {
        this.showError = false;
      }, 5000);
      return
    }
    this.isLoading = true;
    this.apiService.saveContactForm(this.contactForm.value).subscribe({
      next: (res) => {
        this.resetForm();
      },
      error: (err) => {
        console.log('err:: faied', err);
      },
    });
  }

  resetForm(){
      this.formSubmitted = true;
      this.contactForm.reset();
      this.isLoading = false;
      setTimeout(() => {
        this.formSubmitted = false;
      }, 5000);
  }
}
