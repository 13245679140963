import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from './home/home.component'
const routes: Routes = [
 { path: 'chat', loadChildren: () => import('./chat-conversations/chat-conversations.module').then(m => m.ChatConversationsModule) },
  {
    path: '',
    component: HomeComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
