import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient) {

    
   }
   saveContactForm(body: any) {
    const baseUrl = `${environment.doorbellApi}applications/${environment.doorbellId}/submit`
    const objBody = {
      key: environment.doorbellKey,
      email: body['email'],
      message: 'message',
      properties: body
    }
    console.log(objBody)
    return this._http.post(
      baseUrl,
      objBody
    );
  }
}
